import React, { useState } from 'react';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import './DateRangePicker.css';

// Extend Day.js plugins
dayjs.extend(isBetween);
dayjs.extend(customParseFormat);

export default function AQDateRangePicker({ start, end, onChange }) {
    // Define initial values based on props
    const [startDate, setStartDate] = useState((start !== undefined) ? dayjs(start).startOf('day') : dayjs().startOf('day'));
    const [endDate, setEndDate] = useState((end !== undefined) ? dayjs(end).endOf('day') : dayjs().endOf('day'));
    const [currentMonth, setCurrentMonth] = useState(dayjs());
    const [isOpen, setIsOpen] = useState(false); // State to manage the popup visibility

    // Temp state for hours and periods
    const [tempStartHour, setTempStartHour] = useState(startDate.format('hh'));
    const [tempStartPeriod, setTempStartPeriod] = useState(startDate.format('A'));
    const [tempEndHour, setTempEndHour] = useState(endDate.format('hh'));
    const [tempEndPeriod, setTempEndPeriod] = useState(endDate.format('A'));

    const [tempStartDate, setTempStartDate] = useState(startDate);
    const [tempEndDate, setTempEndDate] = useState(endDate);


    const [isStart, setIsStart] = useState(true);

    const onRangeChange = (dates) => {


        if (dates) {
            const formattedStartDate = dates[0].format();
            const formattedEndDate = dates[1].format();
            
            setStartDate(dates[0]);
            setEndDate(dates[1]);

            if (onChange) {
                onChange(formattedStartDate, formattedEndDate);
            }
        }
    };

    const presets = [
        { label: 'Today', value: [dayjs().startOf('day'), dayjs().endOf('day')] },
        { label: 'Last 7 Days', value: [dayjs().subtract(7, 'day').startOf('day'), dayjs().endOf('day')] },
        { label: 'Last 14 Days', value: [dayjs().subtract(14, 'day').startOf('day'), dayjs().endOf('day')] },
        { label: 'Last 30 Days', value: [dayjs().subtract(30, 'day').startOf('day'), dayjs().endOf('day')] },
        { label: 'Last 90 Days', value: [dayjs().subtract(90, 'day').startOf('day'), dayjs().endOf('day')] },
        { label: 'Last 6 Months', value: [dayjs().subtract(6, 'month').startOf('day'), dayjs().endOf('day')] },
        { label: 'Last Year', value: [dayjs().subtract(1, 'year').startOf('day'), dayjs().endOf('day')] },
    ];

    // Function to determine if a date should be disabled
    const disabledDate = (date) => {
        // Disable all dates after today
        return date.isAfter(dayjs().endOf('day'));
    };

    const handleDateSelect = (date) => {
      if (disabledDate(date)) return; // Prevent selection of disabled dates
  
      if (isStart) {
          if (date.isAfter(tempEndDate)) {
              setTempEndDate(date);
          } else {
              setTempStartDate(date);
          }
          setIsStart(false);
      } else {
          if (date.isBefore(tempStartDate)) {
              setTempStartDate(date);
          } else {
              setTempEndDate(date);
          }
          setIsStart(true);
      }
  };
  
    const handlePrevMonth = () => setCurrentMonth(currentMonth.subtract(1, 'month'));
    const handleNextMonth = () => setCurrentMonth(currentMonth.add(1, 'month'));

    const getDaysInMonth = (month) => {
        const startOfMonth = month.startOf('month').day();
        const daysInMonth = month.daysInMonth();
        const days = [];

        for (let i = 0; i < startOfMonth; i++) days.push(null); // Blank spaces
        for (let i = 1; i <= daysInMonth; i++) days.push(i);

        return days;
    };

    const renderMonth = (month, isStart) => (
        <div className="calendar-container">
            <div className="datepicker-header">
                <button onClick={handlePrevMonth}>&lt;</button>
                <span>{month.format('MMM YYYY')}</span>
                <button onClick={handleNextMonth}>&gt;</button>
            </div>
            <div className="days-grid">
                {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map((day, index) => (
                    <div key={index} className="day-name">{day}</div>
                ))}
                {getDaysInMonth(month).map((day, index) => {
                    const currentDay = month.date(day);
                    const isStartDate = tempStartDate && tempStartDate.isSame(currentDay, 'day');
                    const isEndDate = tempEndDate && tempEndDate.isSame(currentDay, 'day');
                    const isDisabled = disabledDate(currentDay); // Check if the date is disabled
                    return (
                        <div
                            key={index}
                            className={`day ${day ? '' : 'empty'} ${isDisabled ? 'disabled' : ''} ${
                                isStartDate ? 'start-date' : ''
                            } ${isEndDate ? 'end-date' : ''} ${
                              tempStartDate && tempEndDate && dayjs(currentDay).isBetween(tempStartDate, tempEndDate, null, '[]') ? 'in-range' : ''
                            }`}
                            onClick={() => day && handleDateSelect(currentDay)}
                        >
                            {day || ''}
                        </div>
                    );
                })}
            </div>
    
            {/* Time Picker directly under each calendar */}
            <div className="time-picker">
                <span>{isStart ? 'Start Time:' : 'End Time:'}</span>
                <div className="time-select">
                    {/* Temp hour and period selection */}
                    <select
                        value={isStart ? tempStartHour : tempEndHour}
                        onChange={(e) => isStart ? setTempStartHour(e.target.value) : setTempEndHour(e.target.value)}
                    >
                        {[...Array(12).keys()].map((i) => (
                            <option key={i} value={i + 1}>{(i + 1).toString().padStart(2, '0')}</option>
                        ))}
                    </select>
    
                    <select
                        value={isStart ? startDate.format('mm') : endDate.format('mm')}
                        onChange={(e) => isStart
                            ? setStartDate(startDate.set('minute', e.target.value))
                            : setEndDate(endDate.set('minute', e.target.value))
                        }
                    >
                        {[...Array(60).keys()].map((minute) => (
                            <option key={minute} value={minute.toString().padStart(2, '0')}>
                                {minute.toString().padStart(2, '0')}
                            </option>
                        ))}
                    </select>
    
                    <select
                        value={isStart ? tempStartPeriod : tempEndPeriod}
                        onChange={(e) => isStart ? setTempStartPeriod(e.target.value) : setTempEndPeriod(e.target.value)}
                    >
                        <option value="AM">AM</option>
                        <option value="PM">PM</option>
                    </select>
                </div>
            </div>
        </div>
    );

    const convertTo24HourFormat = (hour, period) => {
        const h = parseInt(hour);
        if (period === 'PM' && h !== 12) {
            return h + 12;
        } else if (period === 'AM' && h === 12) {
            return 0;
        }
        return h;
    };

    const handleApply = () => {
      // Apply temp hour and period when the user presses "Apply"
      const finalStartHour = convertTo24HourFormat(tempStartHour, tempStartPeriod);
      const finalEndHour = convertTo24HourFormat(tempEndHour, tempEndPeriod);
  
      const finalStartDate = tempStartDate.set('hour', finalStartHour).set('minute', tempStartDate.minute());
      const finalEndDate = tempEndDate.set('hour', finalEndHour).set('minute', tempEndDate.minute());
  
      setStartDate(finalStartDate);
      setEndDate(finalEndDate);
  
      onRangeChange([finalStartDate, finalEndDate]); // Call onRangeChange here with final dates
      setIsOpen(false); // Close the popup on apply
  };
  
  const handlePresetSelect = (dates) => {
    setTempStartDate(dates[0]);
    setTempEndDate(dates[1]);
  };


    return (
        <div className="date-range-picker-popup-container">
            {/* Date input that opens the popup */}
            <div className="date-input" onClick={() => setIsOpen(!isOpen)}>
                <span>{startDate ? startDate.format('YYYY/MM/DD') : 'Start date'}</span>
                <span> → </span>
                <span>{endDate ? endDate.format('YYYY/MM/DD') : 'End date'}</span>
                <span className="calendar-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" style={{ width: '24px', height: '24px' }}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5" />
                    </svg>
                </span>
            </div>

            {/* Conditionally render the popup only when isOpen is true */}
            {isOpen && (
              <div className="popup-container">
                <div className="popup-content">
                  {/* Presets on the left */}
                  <div className="presets">
                      {presets.map((preset) => (
                          <div key={preset.label} className="preset" onClick={() => handlePresetSelect(preset.value)}>
                              {preset.label}
                          </div>
                      ))}
                  </div>
          
                  {/* Unified calendar with time pickers under each month */}
                  <div className="unified-calendar">
                      {renderMonth(currentMonth, true)} {/* Start Date Calendar */}
                      {renderMonth(currentMonth.add(1, 'month'), false)} {/* End Date Calendar */}
                  </div>
              </div>
          
              {/* Apply Button */}
              <div className="apply-button-container">
                  <button className="apply-button" onClick={handleApply}>
                      Apply
                  </button>
              </div>
            </div>
            )}
        </div>
    );
}
